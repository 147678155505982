import { type ReactNode, type ReactText } from "react";
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    type BoxProps,
    type FlexProps,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Divider,
    Image,
} from "@chakra-ui/react";

import {
    FiHome,
    FiCompass,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiCommand,
    FiBookOpen,
    FiDollarSign 
} from "react-icons/fi";

import { type IconType } from "react-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";

interface LinkItemProps {
    name: string;
    icon: IconType;
    route: string;
}
const LinkItems: LinkItemProps[] = [
    { name: "Home", icon: FiHome, route: "/" },
    { name: "View", icon: FiCompass, route: "/view" },
    { name: "MinvestEd", icon: FiBookOpen, route: "/MinvestEd" },
    { name: "Simvest", icon: FiDollarSign, route: "/Simvest" },
    { name: "Assistant", icon: FiCommand, route: "/assistant" },
    { name: "Profile", icon: FiSettings, route: "/profile" },
];

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box minH="100vh" bg="gray.900">
            <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

function SidebarContent({ onClose, ...rest }: SidebarProps) {
    return (
        <Box
            transition="3s ease"
            bg="gray.900"
            borderRight="1px"
            borderRightColor="gray.700"
            w={{ base: "full", md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Image
                    src="https://static.wixstatic.com/media/2317a4_31e8c0f34e6041ef89660e29921c06cd~mv2.png/v1/fill/w_118,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LogoWhiteRing%20(1)_PNG.png"
                    width={50}
                    height={50}
                    alt="Minvest Logo"
                />

                <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
            </Flex>
            <Divider w="80%" mx="10%" borderColor="white" borderWidth="1px" />
            <br />
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} route={link.route}>
                    {link.name}
                </NavItem>
            ))}

            <Divider my="200%" w="80%" mx="10%" borderColor="white" borderWidth="1px" />
        </Box>
    );
}

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    route: string;
}
function NavItem({ icon, children, route, ...rest }: NavItemProps) {
    return (
        <NavLink to={route}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                fontWeight="bold"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: "purple.400",
                    color: "white",
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: "white",
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </NavLink>
    );
}

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
function MobileNav({ onOpen, ...rest }: MobileProps) {
    const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg="gray.900"
            borderBottomWidth="1px"
            borderBottomColor="gray.700"
            justifyContent={{ base: "space-between", md: "flex-end" }}
            {...rest}
        >
            <IconButton
                display={{ base: "flex", md: "none" }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            {/* <Text
                display={{ base: "flex", md: "none" }}
                fontSize="2xl"
                fontFamily="montserrat"
                fontWeight="bold"
            >
                Logo
            </Text> */}

            <HStack spacing={{ base: "0", md: "6" }}>
                <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
                <Flex alignItems="center">
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
                            <HStack>
                                <Avatar size="sm" src={user?.picture} />
                                <VStack
                                    display={{ base: "none", md: "flex" }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    <Text fontSize="sm">{user?.name}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        User
                                    </Text>
                                </VStack>
                                <Box display={{ base: "none", md: "flex" }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList bg="gray.900" borderColor="gray.700">
                            <MenuItem onClick={async () => logout()}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
}
