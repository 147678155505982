import React from "react";
import { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);
import {
    Accordion,
    AccordionPanel,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    Badge,
    ButtonGroup,
    Box,
    CloseButton,
    IconButton,
    Input,
    Text,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tooltip as ChakraTooltip,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Spinner,
    HStack,
    Divider,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { InfoOutlineIcon, MinusIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { AddIcon } from "@chakra-ui/icons";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { type Entry, type UserMetadata } from "../models";
import { Line } from "react-chartjs-2";
import axios from "axios";

const CustomTooltip: React.FC<{ label: string }> = ({ label }) => {
    return (
        <ChakraTooltip label={label} fontSize="md">
            <InfoOutlineIcon color="purple.400" as="span" alignSelf="center" m={2} boxSize={4} />
        </ChakraTooltip>
    );
};

function View() {
    // Add a state variable for holding the fetched data
    const [minvestData, setMinvestData] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState<Entry | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingMetadata, setIsLoadingMetadata] = React.useState(true);
    const [stockPrices, setStockPrices] = useState<Array<number>>([]);
    const [stockDates, setStockDates] = useState<Array<string>>();
    const [chartMovingAverage, setChartMovingAverage] = useState<boolean>(false);
    const { user, loginWithRedirect } = useAuth0();
    let [userMetadata, setUserMetadata] = React.useState<UserMetadata>({
        investmentAmount: 1000,
        finalInvestmentAmount: 2000,
        investmentDuration: 12,
        riskLevel: "low",
        selectedStocks: [],
    });

    const [selectedStocks, setSelectedStocks] = useState<Entry[]>(userMetadata.selectedStocks);
    const [draggedEntry, setDraggedEntry] = useState<Entry | null>(null);
    const [update, setUpdate] = React.useState(false);

    const handleDragStart = (e: React.DragEvent<HTMLTableRowElement>, entry: Entry) => {
        setDraggedEntry(entry);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.clientY < 20) {
            const scrollUp = () => {
                window.scrollBy(0, -1);
                if (window.scrollY > 0) {
                    requestAnimationFrame(scrollUp);
                }
            };
            requestAnimationFrame(scrollUp);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (draggedEntry) {
            setSelectedStocks((prevStocks) => [...prevStocks, draggedEntry]);
            setUpdate(true);
        }
    };

    const handleClick = (entry: Entry) => {
        setSelectedEntry(entry);
        setIsModalOpen(true);
        fetchStockPriceData(entry.Ticker);
    };

    const fetchStockPriceData = async (ticker: string) => {
        const stockPriceData = await axios.get(
            `https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?apikey=aa6917239e15c8c237871755eb08dda3`
        );
        let prices: Float32List = [];
        let dates: Array<string> = [];

        for (let index = 0; index < 450; index++) {
            prices.push(stockPriceData.data.historical[index]?.adjClose);
            dates.push(stockPriceData.data.historical[index]?.date);
        }
        setStockPrices(prices.reverse());
        setStockDates(dates.reverse());
    };

    const calculateMovingAverage = (arr: number[], windowSize: number): number[] => {
        const result: number[] = [];
        for (let i = 0; i < arr.length; i++) {
            const windowStart = Math.max(0, i - windowSize + 1);
            const windowEnd = i + 1;
            const windowValues = arr.slice(windowStart, windowEnd);
            const sum = windowValues.reduce((a, b) => a + b, 0);
            const average = sum / windowValues.length;
            result.push(average);
        }
        return result;
    }

    function analyzeSignal(
        prices: number[],
        ma50: number[],
        ma200: number[]
      ) {
        // Check if lengths of arrays are equal
        if (prices.length !== ma50.length || prices.length !== ma200.length) {
          console.error("Unequal lengths of arrays. Please ensure all arrays have the same size.");
          return "neutral";
        }
        // Loop through each day's data
        for (let i = 0; i < prices.length - 1; i++) {
          const price = prices[i];
      
          // Check for bullish signal (cross above MA and 50d MA > 200d MA)
          if (
            (price > ma50[i] && prices[i + 1] <= ma50[i + 1]) ||
            (price > ma200[i] && prices[i + 1] <= ma200[i + 1])
          ) {
              if(ma50[0] > ma200[0]) {
                return "bullish signal";
              } else {
                return "neutral";
              }
          }
      
          // Check for bearish signal (cross below MA and 50d MA < 200d MA)
          if (
            (price < ma50[i] && prices[i + 1] >= ma50[i + 1]) ||
            (price < ma200[i] && prices[i + 1] >= ma200[i + 1])
          ) {
              if(ma50[0] < ma200[0]) {
                
                return "bearish signal";
              } else {
                return "neutral";
              }
          }
        }
      
        // No signal found
        return "neutral";
      }

    const handleRemoveStock = (companyToRemove: string) => {
        const updatedStocks = selectedStocks.filter((stock) => stock.Company !== companyToRemove);
        setSelectedStocks(updatedStocks);
        setUpdate(true);
    };

    const handleSubmit = async () => {
        const userMetadata = {
            selectedStocks: selectedStocks,
        };
        try {
            const response = await fetch("https://beta.minvestfinance.com:3001/update-user-metadata", {
            // const response = await fetch("http://localhost:3001/update-user-metadata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: user?.sub,
                    userMetadata,
                }),
            });
            if (response.ok) {
                // alert("Profile updated successfully!");
                fetchUserMetadata();
            } else {
                throw new Error("Failed to update profile");
            }
        } catch (error) {
            console.error("Error updating user metadata:", error);
            alert(`Failed to update profile. Please try again.${user?.sub}`);
        }
    };

    const isInitialRender = React.useRef(true);

    useEffect(() => {
        if (update) {
            handleSubmit();
            setUpdate(false);
        }
    }, [selectedStocks]);

    useEffect(() => {
        setSelectedStocks(userMetadata.selectedStocks);
    }, [userMetadata]);

    const uniqueStocks = Array.from(
        new Set(userMetadata.selectedStocks?.map((stock) => stock.Company))
    );
    const stocksWithCount = uniqueStocks?.map((uniqueStock) => ({
        company: uniqueStock,
        finalGrade: userMetadata.selectedStocks.filter((stock) => stock.Company === uniqueStock)[0][
            "Final Grade"
        ],
        sector: userMetadata.selectedStocks.filter((stock) => stock.Company === uniqueStock)[0]
            .Sector,
        count: userMetadata.selectedStocks.filter((stock) => stock.Company === uniqueStock)?.length,
    }));

    const totalValue = userMetadata.selectedStocks?.reduce((acc, stock) => acc + stock.Price, 0);

    const calculateSectorWeights = () => {
        const sectorWeights: Record<string, number> = {};

        userMetadata.selectedStocks?.forEach((stock) => {
            if (sectorWeights[stock.Sector]) {
                sectorWeights[stock.Sector] += stock.Price;
            } else {
                sectorWeights[stock.Sector] = stock.Price;
            }
        });

        for (const sector in sectorWeights) {
            sectorWeights[sector] = sectorWeights[sector] / totalValue;
        }

        return sectorWeights;
    };

    const sectorWeights = calculateSectorWeights();

    const calculateSquaredWeights = () => {
        const squaredWeights: Record<string, number> = {};

        userMetadata.selectedStocks?.forEach((stock) => {
            const weight = stock.Price / totalValue;
            squaredWeights[stock.Ticker] = Math.pow(weight, 2);
        });

        return squaredWeights;
    };

    const squaredWeights = calculateSquaredWeights();

    const sumSquaredWeights = Object.values(squaredWeights)?.reduce(
        (acc, squaredWeight) => acc + squaredWeight,
        0
    );

    let portfolioDiversity;
    if (userMetadata.selectedStocks?.length === 0) {
        portfolioDiversity = 0;
    } else {
        portfolioDiversity = 1 - sumSquaredWeights;
    }

    if (user == null) {
        loginWithRedirect();
        return;
    }

    function getGradeColor(grade: string) {
        grade = grade.toUpperCase();
        switch (grade) {
            case "A":
                return "green.400";
            case "B":
                return "yellow.400";
            case "C":
                return "orange.400";
            case "D":
                return "red.400";
            default:
                return "gray.400";
        }
    }

    async function fetchUserMetadata() {
        console.log("Fetching user metadata...");
        try {
            const response = await fetch("https://beta.minvestfinance.com:3001/get-user-metadata", {
            // const response = await fetch("http://localhost:3001/get-user-metadata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: user?.sub,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setUserMetadata(data.userMetadata);
                userMetadata = data.userMetadata;
            } else {
                throw new Error("Failed to fetch user metadata");
            }
        } catch (error) {
            console.error("Error fetching user metadata:", error);
            alert("Failed to fetch user metadata. Please try again.");
        } finally {
            setIsLoadingMetadata(false);
        }
    }

    async function fetchPersonalizedData() {
        console.log("fetching personalized data...");

        const initialInvestment = userMetadata.investmentAmount.toString();
        const finalInvestment = userMetadata.finalInvestmentAmount.toString();
        const time = userMetadata.investmentDuration.toString();
        const risk = userMetadata.riskLevel;

        const url = `https://beta.minvestfinance.com:105/personalized-data?initialInvestment=${initialInvestment}&finalInvestment=${finalInvestment}&time=${time}&risk=${risk}`;
        // const url = `http://localhost:105/personalized-data?initialInvestment=${initialInvestment}&finalInvestment=${finalInvestment}&time=${time}&risk=${risk}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });


        if (response.ok) {
            const data = await response.json();
            setMinvestData(data);
        } else {
            console.error("Error fetching personalized data:", response.statusText);
        }
    }

    // Fetch data using useEffect
    useEffect(() => {
        fetchUserMetadata();

        // wait 2 seconds before fetching stocks
        setTimeout(function () {
            fetchPersonalizedData();
        }, 2000);
    }, []);

    const [searchStock, setSearchStock] = useState("");
    const [searchTicker, setSearchTicker] = useState("");
    const [searchGrade, setSearchGrade] = useState("");
    const [searchBeta, setSearchBeta] = useState("");
    const [searchIndustry, setSearchIndustry] = useState("");

    const filteredData = minvestData.slice(0, 400)?.filter((entry: Entry) => {
        const companyName = entry.Company.toLowerCase();
        const ticker = entry.Ticker.toLowerCase();
        const grade = entry["Final Grade"];
        const beta = entry.Beta;
        const industry = entry.Sector;
        const tickerSearch = searchTicker.toLowerCase();
        const stockSearch = searchStock.toLowerCase();
        const isGradeMatch = searchGrade === "" || grade === searchGrade;
        let riskLevel = "";
        if (beta < 0.25) {
            riskLevel = "Very Low Risk";
        } else if (beta < 0.75) {
            riskLevel = "Low Risk";
        } else if (beta < 1.25) {
            riskLevel = "Medium Risk";
        } else if (beta < 2) {
            riskLevel = "High Risk";
        } else {
            riskLevel = "Very High Risk";
        }
        const isBetaMatch = searchBeta === "" || riskLevel === searchBeta;
        const isIndustryMatch = searchIndustry === "" || industry === searchIndustry;

        return (
            companyName.includes(stockSearch) &&
            ticker.includes(tickerSearch) &&
            isGradeMatch &&
            isIndustryMatch &&
            (searchBeta === "" || isBetaMatch)
        );
    });

    return (
        <>
            {isLoadingMetadata ? (
                <Box textAlign="center" my={4}>
                    <Spinner color="blue.500" />
                    <Text>Loading profile information...</Text>
                </Box>
            ) : (
                <>
                    {!isModalOpen && (
                        <Box>
                            <Flex direction={"row"}>
                                <Box
                                    bg="gray.800"
                                    width="50%"
                                    borderRadius={10}
                                    mr={4}
                                    p={4}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <Text fontWeight="bold" fontSize={24} mb={2}>
                                        Selected Stocks
                                    </Text>
                                    {stocksWithCount?.map((stock, index) => (
                                        <Box
                                            key={index}
                                            bg="rgb(23,25,35)"
                                            borderRadius={5}
                                            p={2}
                                            mb={2}
                                            position="relative"
                                        >
                                            {
                                                <Text as="span" fontWeight={"bold"}>
                                                    {stock.company}
                                                </Text>
                                            }
                                            {stock.count > 1 && (
                                                <Badge as="span" ml={2}>
                                                    {stock.count}
                                                </Badge>
                                            )}
                                            <IconButton
                                                icon={<CloseButton />}
                                                aria-label="Remove stock"
                                                variant="ghost"
                                                size="sm"
                                                color="white"
                                                position="absolute"
                                                right={2}
                                                top="50%"
                                                transform="translateY(-50%)"
                                                onClick={() => handleRemoveStock(stock.company)}
                                            />
                                            <Box>
                                                <Text
                                                    as="span"
                                                    color="purple.400"
                                                    fontWeight="bold"
                                                >
                                                    Final Grade:
                                                </Text>
                                                {stock.finalGrade && (
                                                    <Text
                                                        color={getGradeColor(stock.finalGrade)}
                                                        as="span"
                                                        marginLeft={1}
                                                        marginRight={1}
                                                        fontWeight="bold"
                                                    >
                                                        {stock.finalGrade}
                                                    </Text>
                                                )}
                                                <Text
                                                    as="span"
                                                    marginLeft={1}
                                                    marginRight={1}
                                                    color="purple.400"
                                                    fontWeight="bold"
                                                >
                                                    Industry:
                                                </Text>
                                                <Text as="span" color="white" fontWeight="bold">
                                                    {stock.sector}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box bg="gray.800" width="50%" borderRadius={10} p={4}>
                                    <Text fontWeight="bold" fontSize={24} mb={2}>
                                        Stock Portfolio Diversity
                                    </Text>
                                    <Text fontSize={18} mb={2}>
                                        Portfolio Total:{" "}
                                        <Text as="span" color="purple.400" fontWeight="bold">
                                            ${totalValue?.toFixed(2)}
                                        </Text>
                                    </Text>
                                    <Text fontSize={18} mb={2}>
                                        Sector Weights:{" "}
                                        {userMetadata.selectedStocks?.length > 0 && (
                                            <Pie
                                                data={{
                                                    labels: Object.entries(sectorWeights)?.map(
                                                        ([sector, weight]) => sector
                                                    ),
                                                    datasets: [
                                                        {
                                                            data: Object.entries(
                                                                sectorWeights
                                                            )?.map(([sector, weight]) => weight),
                                                            backgroundColor: [
                                                                "#FF6384",
                                                                "#36A2EB",
                                                                "#FFCE56",
                                                                "#4CAF50",
                                                                "#9966FF",
                                                                "#FF5733",
                                                                "#33FF57",
                                                                "#5733FF",
                                                                "#FF33A1",
                                                                "#33A1FF",
                                                                "#A1FF33",
                                                            ],
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            labels: {
                                                                color: "white",
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    </Text>
                                    <Text fontSize={18} mb={2}>
                                        Diversity Score (D):&nbsp;
                                        {(Number(portfolioDiversity) * 100).toFixed(2)}%
                                        <Slider
                                            min={0}
                                            max={0.91}
                                            step={0.01}
                                            value={Number(portfolioDiversity)}
                                            colorScheme="gradient"
                                            bgGradient="linear(to-r, red.500, yellow.500, green.500)"
                                            borderRadius={10}
                                        >
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb boxSize={4} />
                                        </Slider>
                                    </Text>
                                </Box>
                            </Flex>

                            <Box bg="gray.800" width="100%" my={5} mx={0} borderRadius={10} p={4}>
                                <Box
                                    bg="gray.800"
                                    width="100%"
                                    my={5}
                                    mx={0}
                                    borderRadius={10}
                                    p={4}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Input
                                        width="20%"
                                        type="text"
                                        placeholder="Search Stock"
                                        mr={2}
                                        value={searchStock}
                                        onChange={(e) => setSearchStock(e.target.value)}
                                    />
                                    <Input
                                        width="20%"
                                        type="text"
                                        placeholder="Search Ticker"
                                        mr={2}
                                        value={searchTicker}
                                        onChange={(e) => setSearchTicker(e.target.value)}
                                    />
                                    <Menu>
                                        <MenuButton
                                            px={4}
                                            py={2}
                                            transition="all 0.2s"
                                            borderRadius="md"
                                            borderWidth="1px"
                                            _hover={{ bg: "gray.400" }}
                                            _expanded={{ bg: "blue.400" }}
                                            _focus={{ boxShadow: "outline" }}
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            mr={2}
                                        >
                                            {searchGrade ? `${searchGrade}` : "Final Grade"}{" "}
                                            <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => setSearchGrade("A")}>
                                                A
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchGrade("B")}>
                                                B
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchGrade("C")}>
                                                C
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchGrade("D")}>
                                                D
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                    <Menu>
                                        <MenuButton
                                            px={4}
                                            py={2}
                                            mr={2}
                                            transition="all 0.2s"
                                            borderRadius="md"
                                            borderWidth="1px"
                                            _hover={{ bg: "gray.400" }}
                                            _expanded={{ bg: "blue.400" }}
                                            _focus={{ boxShadow: "outline" }}
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            value={searchBeta}
                                        >
                                            {searchBeta ? `${searchBeta}` : "Beta"}{" "}
                                            <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                onClick={() => setSearchBeta("Very Low Risk")}
                                            >
                                                Very Low Risk
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchBeta("Low Risk")}>
                                                Low Risk
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchBeta("Medium Risk")}>
                                                Medium Risk
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchBeta("High Risk")}>
                                                High Risk
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchBeta("Very High Risk")}
                                            >
                                                Very High Risk
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                    <Menu>
                                        <MenuButton
                                            px={4}
                                            py={2}
                                            mr={2}
                                            transition="all 0.2s"
                                            borderRadius="md"
                                            borderWidth="1px"
                                            _hover={{ bg: "gray.400" }}
                                            _expanded={{ bg: "blue.400" }}
                                            _focus={{ boxShadow: "outline" }}
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            value={searchIndustry}
                                        >
                                            {searchIndustry ? `${searchIndustry}` : "Industry"}{" "}
                                            <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                onClick={() =>
                                                    setSearchIndustry("Information Technology")
                                                }
                                            >
                                                Information Technology
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Health Care")}
                                            >
                                                Health Care
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Financials")}
                                            >
                                                Financials
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    setSearchIndustry("Consumer Discretionary")
                                                }
                                            >
                                                Consumer Discretionary
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    setSearchIndustry("Communication Services")
                                                }
                                            >
                                                Communication Services
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Industrials")}
                                            >
                                                Industrials
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    setSearchIndustry("Consumer Staples")
                                                }
                                            >
                                                Consumer Staples
                                            </MenuItem>
                                            <MenuItem onClick={() => setSearchIndustry("Energy")}>
                                                Energy
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Utilities")}
                                            >
                                                Utilities
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Real Estate")}
                                            >
                                                Real Estate
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => setSearchIndustry("Materials")}
                                            >
                                                Materials
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                    <Button
                                        onClick={() => {
                                            setSearchStock("");
                                            setSearchTicker("");
                                            setSearchGrade("");
                                            setSearchBeta("");
                                            setSearchIndustry("");
                                        }}
                                    >
                                        Clear Filters
                                    </Button>
                                </Box>
                                <Divider></Divider>

                                <Table variant="simple" size="lg">
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Ticker</Th>
                                            <Th>Final Grade</Th>
                                            <Th>Risk Level</Th>
                                            <Th>Industry</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {filteredData.slice(0, 400)?.map((entry: Entry, index) => (
                                            <Tr
                                                key={index}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, entry)}
                                                onClick={() => handleClick(entry)}
                                            >
                                                <Td
                                                    fontWeight="bold"
                                                    color={getGradeColor(entry["Final Grade"])}
                                                >
                                                    <Box display="flex" alignItems="center">
                                                        <Text paddingRight={4}>
                                                            {entry.Company}
                                                        </Text>
                                                        {entry &&
                                                            !selectedStocks.some(
                                                                (stock) =>
                                                                    stock.Ticker === entry.Ticker
                                                            ) && (
                                                                <Button
                                                                    size="sm"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (entry) {
                                                                            setSelectedStocks(
                                                                                (prevStocks) => [
                                                                                    ...prevStocks,
                                                                                    entry,
                                                                                ]
                                                                            );
                                                                            setUpdate(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Text>Add To Portfolio</Text>
                                                                </Button>
                                                            )}
                                                        {entry &&
                                                            selectedStocks.some(
                                                                (stock) =>
                                                                    stock.Ticker === entry.Ticker
                                                            ) && (
                                                                <ButtonGroup size="sm" isAttached>
                                                                    <IconButton
                                                                        aria-label="Minus"
                                                                        icon={<MinusIcon />}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            if (entry) {
                                                                                const indexToRemove =
                                                                                    selectedStocks.findIndex(
                                                                                        (stock) =>
                                                                                            stock.Company ===
                                                                                            entry.Company
                                                                                    );

                                                                                if (
                                                                                    indexToRemove !==
                                                                                    -1
                                                                                ) {
                                                                                    const updatedStocks =
                                                                                        [
                                                                                            ...selectedStocks,
                                                                                        ];
                                                                                    updatedStocks.splice(
                                                                                        indexToRemove,
                                                                                        1
                                                                                    );
                                                                                    setSelectedStocks(
                                                                                        updatedStocks
                                                                                    );
                                                                                    setUpdate(true);
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Button>
                                                                        Added{" "}
                                                                        {
                                                                            selectedStocks.filter(
                                                                                (stock) =>
                                                                                    stock.Ticker ===
                                                                                    entry.Ticker
                                                                            ).length
                                                                        }
                                                                    </Button>
                                                                    <IconButton
                                                                        aria-label="Add"
                                                                        icon={<AddIcon />}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            if (entry) {
                                                                                setSelectedStocks(
                                                                                    (
                                                                                        prevStocks
                                                                                    ) => [
                                                                                            ...prevStocks,
                                                                                            entry,
                                                                                        ]
                                                                                );
                                                                                setUpdate(true);
                                                                            }
                                                                        }}
                                                                    />
                                                                </ButtonGroup>
                                                            )}
                                                    </Box>
                                                </Td>
                                                <Td>{entry.Ticker}</Td>
                                                <Td>{entry["Final Grade"]}</Td>
                                                {entry.Beta < 0.25 && (
                                                    <Td fontWeight="bold" color="green.400">
                                                        Very Low Risk
                                                    </Td>
                                                )}
                                                {0.25 <= entry.Beta && entry.Beta < 0.75 && (
                                                    <Td fontWeight="bold" color="green.400">
                                                        Low Risk
                                                    </Td>
                                                )}
                                                {0.75 <= entry.Beta && entry.Beta < 1.25 && (
                                                    <Td fontWeight="bold" color="yellow.400">
                                                        Medium Risk
                                                    </Td>
                                                )}
                                                {1.25 <= entry.Beta && entry.Beta < 2 && (
                                                    <Td fontWeight="bold" color="orange.400">
                                                        High Risk
                                                    </Td>
                                                )}
                                                {2 < entry.Beta && (
                                                    <Td fontWeight="bold" color="red.400">
                                                        Very High Risk
                                                    </Td>
                                                )}
                                                <Td>{entry.Sector}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    )}

                    {isModalOpen && (
                        <Box>
                            <Flex direction={"row"}>
                                <Box bg="gray.800" width="70%" borderRadius={10} mr={4} p={4}>
                                    <Text fontWeight="bold" fontSize={24} mb={2}>
                                        Stock Price Chart
                                    </Text>
                                    {!chartMovingAverage && <Line
                                        data={{
                                            labels: stockDates?.slice(-250),
                                            datasets: [
                                                {
                                                    label: "",
                                                    fill: false,
                                                    backgroundColor: "rgba(255,255,255,1)",
                                                    borderColor: "rgba(159,122,234,1)",
                                                    borderCapStyle: "butt",
                                                    borderDash: [],
                                                    borderDashOffset: 0.0,
                                                    borderJoinStyle: "miter",
                                                    pointBorderColor: "rgba(229,198,70,1)",
                                                    pointBackgroundColor: "#fff",
                                                    pointBorderWidth: 1,
                                                    pointHoverRadius: 5,
                                                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                    pointHoverBorderColor: "rgba(225,225,225,1)",
                                                    pointHoverBorderWidth: 2,
                                                    pointRadius: 2,
                                                    pointHitRadius: 10,
                                                    data: stockPrices.slice(-250),
                                                },
                                            ],
                                        }}
                                        options={{
                                            plugins: { legend: { display: false } },
                                            scales: {
                                                x: { ticks: { display: true,
                                                              color: "white"} },
                                                y: { ticks: { color: "white" } },
                                            },
                                        }}
                                    />}

                                    {chartMovingAverage && <><Line
                                        data={{
                                            labels: stockDates?.slice(-250),
                                            datasets: [
                                                {
                                                    label: "Price",
                                                    fill: false,
                                                    backgroundColor: "rgba(255,255,255,1)",
                                                    borderColor: "rgba(159,122,234,1)",
                                                    borderCapStyle: "butt",
                                                    borderDash: [],
                                                    borderDashOffset: 0.0,
                                                    borderJoinStyle: "miter",
                                                    pointBorderColor: "rgba(229,198,70,1)",
                                                    pointBackgroundColor: "#fff",
                                                    pointBorderWidth: 1,
                                                    pointHoverRadius: 5,
                                                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                    pointHoverBorderColor: "rgba(225,225,225,1)",
                                                    pointHoverBorderWidth: 2,
                                                    pointRadius: 2,
                                                    pointHitRadius: 10,
                                                    data: stockPrices.slice(-250),
                                                },

                                                {
                                                    label: "50 Day Moving Average",
                                                    fill: false,
                                                    backgroundColor: "rgba(255,255,255,1)",
                                                    borderColor: "rgba(102, 255, 51)",
                                                    borderCapStyle: "butt",
                                                    borderDash: [],
                                                    borderDashOffset: 0.0,
                                                    borderJoinStyle: "miter",
                                                    pointBorderColor: "rgba(102, 255, 51)",
                                                    pointBackgroundColor: "#fff",
                                                    pointBorderWidth: 1,
                                                    pointHoverRadius: 5,
                                                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                    pointHoverBorderColor: "rgba(225,225,225,1)",
                                                    pointHoverBorderWidth: 2,
                                                    pointRadius: 2,
                                                    pointHitRadius: 10,
                                                    data: calculateMovingAverage(stockPrices, 50).slice(-250),
                                                },

                                                {
                                                    label: "200 Day Moving Average",
                                                    fill: false,
                                                    backgroundColor: "rgba(255,255,255,1)",
                                                    borderColor: "rgba(255, 153, 0)",
                                                    borderCapStyle: "butt",
                                                    borderDash: [],
                                                    borderDashOffset: 0.0,
                                                    borderJoinStyle: "miter",
                                                    pointBorderColor: "rgba(255, 153, 0)",
                                                    pointBackgroundColor: "#fff",
                                                    pointBorderWidth: 1,
                                                    pointHoverRadius: 5,
                                                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                    pointHoverBorderColor: "rgba(225,225,225,1)",
                                                    pointHoverBorderWidth: 2,
                                                    pointRadius: 2,
                                                    pointHitRadius: 10,
                                                    data: calculateMovingAverage(stockPrices, 200).slice(-250),
                                                },
                                            ],
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: true, // Make sure the legend is visible
                                                    labels: {
                                                        color: 'rgb(255,  255,  255)' // Set the color of the legend text to red
                                                    }
                                                }
                                            },
                                            scales: {
                                                x: { ticks: { display: true,
                                                              color: "white"} },
                                                y: { ticks: { color: "white" } },
                                            },
                                        }}
                                    />
                                    {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "bullish signal" && <Text fontWeight='bold' textAlign='center' color='green.400'><TriangleUpIcon color='green.400'/>Bullish divergence (Buy signal)</Text>}
                                    {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "bearish signal" && <Text fontWeight='bold' textAlign='center' color='red.400'><TriangleDownIcon color='red.400'/>Bearish divergence (Sell signal)</Text>}
                                    {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "neutral" && <Text fontWeight='bold' textAlign='center'>Neutral signal</Text>}</>}
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setChartMovingAverage(false)
                                        }}
                                        sx={{ marginTop: 2 }}
                                    >
                                        <Text>Default</Text>
                                    </Button>
                                    <Button
                                        sx={{ marginLeft: 2, marginTop: 2 }}
                                        size="sm"
                                        onClick={() => {
                                            setChartMovingAverage(true)
                                        }}
                                    >
                                        <Text>Moving Average</Text>
                                    </Button>
                                </Box>
                                <Box bg="gray.800" width="30%" borderRadius={10} p={4}>
                                    <Flex direction={"row"}>
                                        <Text fontWeight="bold" fontSize={24} mb={2}>
                                            Overview
                                        </Text>
                                        <IconButton
                                            icon={<CloseButton />}
                                            aria-label="Close Stock"
                                            variant="ghost"
                                            size="sm"
                                            color="white"
                                            position={"absolute"}
                                            right={4}
                                            onClick={() => setIsModalOpen(false)}
                                        />
                                    </Flex>

                                    <Flex direction={"row"}>
                                        <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                            Price:
                                        </Text>
                                        <Text fontSize={14} mb={2}>
                                            {selectedEntry?.Price}
                                        </Text>
                                    </Flex>
                                    <Flex direction={"row"}>
                                        <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                            Risk Level:
                                        </Text>
                                        <Text fontSize={14} mb={2}>
                                            {selectedEntry?.Beta}
                                        </Text>
                                    </Flex>
                                    <Flex direction={"row"}>
                                        <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                            Industry Name:
                                        </Text>
                                        <Text fontSize={14} mb={2}>
                                            {selectedEntry?.Sector}
                                        </Text>
                                    </Flex>
                                    {selectedEntry &&
                                        !selectedStocks.some(
                                            (stock) => stock.Ticker === selectedEntry.Ticker
                                        ) && (
                                            <Button
                                                onClick={() => {
                                                    if (selectedEntry) {
                                                        setSelectedStocks((prevStocks) => [
                                                            ...prevStocks,
                                                            selectedEntry,
                                                        ]);
                                                        setUpdate(true);
                                                    }
                                                }}
                                            >
                                                <Text>Add To Portfolio</Text>
                                            </Button>
                                        )}
                                    {selectedEntry &&
                                        selectedStocks.some(
                                            (stock) => stock.Ticker === selectedEntry.Ticker
                                        ) && (
                                            <ButtonGroup size="sm" isAttached>
                                                <IconButton
                                                    aria-label="Minus"
                                                    icon={<MinusIcon />}
                                                    onClick={() => {
                                                        if (selectedEntry) {
                                                            const indexToRemove =
                                                                selectedStocks.findIndex(
                                                                    (stock) =>
                                                                        stock.Company ===
                                                                        selectedEntry.Company
                                                                );

                                                            if (indexToRemove !== -1) {
                                                                const updatedStocks = [
                                                                    ...selectedStocks,
                                                                ];
                                                                updatedStocks.splice(
                                                                    indexToRemove,
                                                                    1
                                                                );
                                                                setSelectedStocks(updatedStocks);
                                                                setUpdate(true);
                                                            }
                                                        }
                                                    }}
                                                />
                                                <Button>
                                                    Added{" "}
                                                    {
                                                        selectedStocks.filter(
                                                            (stock) =>
                                                                stock.Ticker ===
                                                                selectedEntry.Ticker
                                                        ).length
                                                    }
                                                </Button>
                                                <IconButton
                                                    aria-label="Add"
                                                    icon={<AddIcon />}
                                                    onClick={() => {
                                                        if (selectedEntry) {
                                                            setSelectedStocks((prevStocks) => [
                                                                ...prevStocks,
                                                                selectedEntry,
                                                            ]);
                                                            setUpdate(true);
                                                        }
                                                    }}
                                                />
                                            </ButtonGroup>
                                        )}
                                </Box>
                            </Flex>
                            <Flex direction={"row"}>
                                <Box
                                    bg="gray.800"
                                    width="50%"
                                    borderRadius={10}
                                    mt={4}
                                    mr={4}
                                    p={4}
                                >
                                    <Flex direction={"row"}>
                                        <Text fontWeight="bold" fontSize={30}>
                                            {selectedEntry?.Company}
                                        </Text>
                                        <Text color="purple.400" ml={2} mt={3} fontWeight="bold">
                                            Final Grade:
                                        </Text>
                                        {selectedEntry?.["Final Grade"] && (
                                            <Text
                                                mt={3}
                                                color={getGradeColor(
                                                    selectedEntry?.["Final Grade"]
                                                )}
                                                marginLeft={1}
                                                marginRight={1}
                                                fontWeight="bold"
                                            >
                                                {selectedEntry?.["Final Grade"]}
                                            </Text>
                                        )}
                                    </Flex>
                                    <Text fontSize={18} mb={2}>
                                        {selectedEntry?.Ticker}
                                    </Text>
                                    <Text fontSize={14}>{selectedEntry?.Description}</Text>
                                </Box>
                                <Box bg="gray.800" width="50%" borderRadius={10} mt={4} p={4}>
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex="1" textAlign="left">
                                                        <Flex direction={"row"}>
                                                            <Text
                                                                as="span"
                                                                fontWeight={"bold"}
                                                                fontSize={24}
                                                            >
                                                                Valuation Grade
                                                            </Text>
                                                            {selectedEntry?.["Valuation Grade"] && (
                                                                <Text
                                                                    as="span"
                                                                    fontSize={24}
                                                                    color={getGradeColor(
                                                                        selectedEntry?.[
                                                                        "Valuation Grade"
                                                                        ]
                                                                    )}
                                                                    marginLeft={4}
                                                                    marginRight={1}
                                                                    fontWeight="bold"
                                                                >
                                                                    {
                                                                        selectedEntry?.[
                                                                        "Valuation Grade"
                                                                        ]
                                                                    }
                                                                </Text>
                                                            )}
                                                            <CustomTooltip label="A measure of how overpriced or underpriced a stock is. With these metrics, a lower number compared to industry peers is better."></CustomTooltip>
                                                        </Flex>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much people are willing to pay for a company's shares compared to the money the company makes."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        P/E Ratio (Price-to-Earnings Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.PE?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["PE Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["PE Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["PE Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much people are willing to pay for a company's shares compared to the money the company makes from selling stuff."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        P/S Ratio (Price-to-Sales Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.PS?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["PS Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["PS Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["PS Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much people are willing to pay for a company's shares compared to the value of all its stuff (like buildings and equipment)."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        P/B Ratio (Price-to-Book Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.PB?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["PB Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["PB Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["PB Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="Considers how fast a company is growing when looking at its share price compared to its earnings."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        PEG Ratio (Price/Earnings to Growth Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.PEG?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["PEG Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["PEG Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["PEG Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much people think a company is worth compared to the money it makes from selling things."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        EV/R Ratio (Enterprise Value-to-Revenue
                                                        Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.["EV/S"]?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["EV/S Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["EV/S Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["EV/S Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much people think a company is worth compared to the money it makes, not counting some tricky things."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        EV/EBITDA Ratio (Enterprise Value-to-EBITDA
                                                        Ratio)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.["EV/EBITDA"]?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["EV/EBITDA Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["EV/EBITDA Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["EV/EBITDA Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex="1" textAlign="left">
                                                        <Flex direction={"row"}>
                                                            <Text fontWeight={"bold"} fontSize={24}>
                                                                Financial Grade
                                                            </Text>
                                                            {selectedEntry?.["Financial Grade"] && (
                                                                <Text
                                                                    as="span"
                                                                    fontSize={24}
                                                                    color={getGradeColor(
                                                                        selectedEntry?.[
                                                                        "Financial Grade"
                                                                        ]
                                                                    )}
                                                                    marginLeft={4}
                                                                    marginRight={1}
                                                                    fontWeight="bold"
                                                                >
                                                                    {
                                                                        selectedEntry?.[
                                                                        "Financial Grade"
                                                                        ]
                                                                    }
                                                                </Text>
                                                            )}
                                                            <CustomTooltip label="These show if the company is in a good financial position to keep growing and generating shareholder value."></CustomTooltip>
                                                        </Flex>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Flex direction={"row"}>
                                                    <Text
                                                        as={"span"}
                                                        fontWeight={"bold"}
                                                        fontSize={18}
                                                        mt={0.5}
                                                        color={"purple.400"}
                                                    >
                                                        Management Effectiveness
                                                    </Text>
                                                    <CustomTooltip label="This shows how good of a job the company is doing with generating returns from assets and money that shareholders invest in it. Higher values are always better."></CustomTooltip>
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How good a company is at making money with all the things it owns."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Return on Assets (ROA)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.ROA?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["ROA Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["ROA Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["ROA Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How good a company is at making money compared to the money its owners put in."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Return on Equity (ROE)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.ROE?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["ROE Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["ROE Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["ROE Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex direction={"row"} mt={2}>
                                                    <Text
                                                        as={"span"}
                                                        fontWeight={"bold"}
                                                        fontSize={18}
                                                        mt={0.5}
                                                        color={"purple.400"}
                                                    >
                                                        Profitability
                                                    </Text>
                                                    <CustomTooltip label="This shows how good the company is at making money and minimizing costs from its operations. Higher values are always better."></CustomTooltip>
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much money a company has left after subtracting what it cost to make and sell its products."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Gross Margin
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.GM?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["GM Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["GM Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["GM Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much money a company makes before subtracting some tricky things, compared to its revenue."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        EBITDA Margin (Earnings Before Interest,
                                                        Taxes, Depreciation, and Amortization
                                                        Margin)
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.EM?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["EM Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["EM Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["EM Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much money a company makes after subtracting all the costs of running the business, compared to its revenue."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Operating Margin
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.OM?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["OM Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["OM Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["OM Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much money a company makes after subtracting all the costs, including the tricky things, compared to its revenue."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Profit Margin
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.PM?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["PM Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["PM Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["PM Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex direction={"row"} mt={2}>
                                                    <Text
                                                        as={"span"}
                                                        fontWeight={"bold"}
                                                        fontSize={18}
                                                        mt={0.5}
                                                        color={"purple.400"}
                                                    >
                                                        Liquidity/Leverage
                                                    </Text>
                                                    <CustomTooltip label="This shows if the company is taking on too much debt and might be unable to meet short term obligations. Lower values are usually better."></CustomTooltip>
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="Whether a company has enough stuff (assets) to cover what it owes (liabilities) right now."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Current Ratio
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.CR?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["CR Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["CR Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["CR Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much a company owes compared to how much its owners put in."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Debt to Equity Ratio
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.DE?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["DE Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["DE Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["DE Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>

                                                <Flex direction={"row"} mt={2}>
                                                    <Text
                                                        as={"span"}
                                                        fontWeight={"bold"}
                                                        fontSize={18}
                                                        mt={0.5}
                                                        color={"purple.400"}
                                                    >
                                                        Growth
                                                    </Text>
                                                    <CustomTooltip label="This is self-explanatory; is the company growing its revenue and profit? Higher numbers are always better."></CustomTooltip>
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much more money a company is making compared to before."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Earnings Growth
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.EG?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["EG Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["EG Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["EG Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                                <Flex
                                                    direction={"row"}
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <CustomTooltip label="How much more money a company is making from selling things compared to before."></CustomTooltip>
                                                    <Text
                                                        as="span"
                                                        fontWeight={"bold"}
                                                        fontSize={14}
                                                        flex="1"
                                                    >
                                                        Revenue Growth
                                                    </Text>

                                                    <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        mr={6}
                                                        fontSize={14}
                                                    >
                                                        {selectedEntry?.RG?.toFixed(2)}
                                                    </Text>

                                                    {selectedEntry?.["RG Grade"] && (
                                                        <Text
                                                            as="span"
                                                            fontSize={14}
                                                            color={getGradeColor(
                                                                selectedEntry?.["RG Grade"]
                                                            )}
                                                            marginLeft={4}
                                                            fontWeight="bold"
                                                        >
                                                            {selectedEntry?.["RG Grade"]}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </Flex>
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

export default View;
