// src/theme.js or src/theme.ts

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
        heading: "Montserrat, sans-serif",
        body: "Montserrat, sans-serif",
    },
    // make the default theme dark mode
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
});

export default theme;
