import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { addHours } from "date-fns";
import axios from "axios";
import { useBreakpointValue } from "@chakra-ui/react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

import {
    Accordion,
    AccordionPanel,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    ButtonGroup,
    Box,
    CloseButton,
    IconButton,
    Text,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tooltip as ChakraTooltip,
    Button,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";

import { InfoOutlineIcon, MinusIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { AddIcon } from "@chakra-ui/icons";
import { useDisclosure } from '@chakra-ui/react'

import { type Entry, type UserMetadata } from "../models";
import { Line } from "react-chartjs-2";

const CustomTooltip: React.FC<{ label: string }> = ({ label }) => {
    return (
        <ChakraTooltip label={label} fontSize="md">
            <InfoOutlineIcon color="purple.400" as="span" alignSelf="center" m={2} boxSize={4} />
        </ChakraTooltip>
    );
};

function Dashboard() {
    let currentValue = 0;
    const data = Array.from({ length: 24 }, (_, i) => {
        const date = addHours(new Date(), -i);
        currentValue += Math.floor(Math.random() * 1000);
        return {
            time: `${date.getHours()}:00`,
            value: currentValue,
        };
    });

    const totalInvested = data[data.length - 1].value;

    const isComputerSize = useBreakpointValue({ base: false, lg: true });
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    // Add a state variable for holding the fetched data
    const [minvestData, setMinvestData] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState<Entry | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
    const { user, loginWithRedirect } = useAuth0();
    const [stockDates, setStockDates] = useState<Array<String>>();
    const [stockPrices, setStockPrices] = useState<Array<number>>([]);
    const [chartMovingAverage, setChartMovingAverage] = useState<boolean>(false);
    const [update, setUpdate] = React.useState(false);
    const [continueAnyway, setContinueAnyway] = useState(false);

    let [userMetadata, setUserMetadata] = useState<UserMetadata>({
        investmentAmount: 0,
        finalInvestmentAmount: 2000,
        investmentDuration: 12,
        riskLevel: "low",
        selectedStocks: [],
    });

    const [selectedStocks, setSelectedStocks] = useState<Entry[]>(userMetadata.selectedStocks);

    const tags = [
        "beta",
        "riskBeta",
        "gradePE",
        "gradePS",
        "gradePB",
        "gradePEG",
        "gradeEVR",
        "gradeEV_EBITDA",
        "returnOnAssets",
        "returnOnEquity",
        "earningsGrowth",
        "revenueGrowth",
        "grossMargins",
        "ebtidaMargins",
        "operatingMargins",
        "profitMargins",
        "gradeRPS",
        "gradeROA",
        "gradeROE",
        "gradeEarningsGrowth",
        "gradeRevenueGrowth",
        "gradeGrossMargins",
        "gradeEbtidaMargins",
        "gradeOperatingMargins",
        "gradeProfitMargins",
    ];

    if (user == null) {
        loginWithRedirect();
    }

    function getGradeColor(grade: string) {
        switch (grade) {
            case "A":
                return "green.400";
            case "B":
                return "yellow.400";
            case "C":
                return "orange.400";
            case "D":
                return "red.400";
            default:
                return "gray.400";
        }
    }

    const fetchStockPriceData = async (ticker: String) => {
        const stockPriceData = await axios.get(
            `https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?apikey=aa6917239e15c8c237871755eb08dda3`
        );
        let prices: Float32List = [];
        let dates: Array<String> = [];

        let currentDate = stockPriceData.data.historical[0].date;

        for (let index = 0; index < 450; index++) {
            prices.push(stockPriceData.data.historical[index]?.adjClose);
            dates.push(stockPriceData.data.historical[index].date);
        }
        setStockPrices(prices.reverse());
        setStockDates(dates.reverse());
    };

    function analyzeSignal(
        prices: number[],
        ma50: number[],
        ma200: number[]
    ) {
        // Check if lengths of arrays are equal
        if (prices.length !== ma50.length || prices.length !== ma200.length) {
            console.error("Unequal lengths of arrays. Please ensure all arrays have the same size.");
            return "neutral";
        }
        // Loop through each day's data
        for (let i = 0; i < prices.length - 1; i++) {
            const price = prices[i];

            // Check for bullish signal (cross above MA and 50d MA > 200d MA)
            if (
                (price > ma50[i] && prices[i + 1] <= ma50[i + 1]) ||
                (price > ma200[i] && prices[i + 1] <= ma200[i + 1])
            ) {
                if (ma50[0] > ma200[0]) {
                    return "bullish signal";
                } else {
                    return "neutral";
                }
            }

            // Check for bearish signal (cross below MA and 50d MA < 200d MA)
            if (
                (price < ma50[i] && prices[i + 1] >= ma50[i + 1]) ||
                (price < ma200[i] && prices[i + 1] >= ma200[i + 1])
            ) {
                if (ma50[0] < ma200[0]) {

                    return "bearish signal";
                } else {
                    return "neutral";
                }
            }
        }

        // No signal found
        return "neutral";
    }

    const handleClick = (entry: Entry) => {
        setSelectedEntry(entry);
        setIsModalOpen(true);
        fetchStockPriceData(entry.Ticker);
    };

    async function fetchUserMetadata() {
        console.log("fetching usermetadata");
        try {
             const response = await fetch("https://beta.minvestfinance.com:3001/get-user-metadata", {
            // const response = await fetch("http://localhost:3001/get-user-metadata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: user?.sub,
                }),
            });

            if (response.ok) {
                const data = await response.json();

                setUserMetadata(data.userMetadata);
                userMetadata = data.userMetadata;
                console.log("response from fetch:", data.userMetadata);
            } else {
                throw new Error("Failed to fetch user metadata");
            }
        } catch (error) {
            console.error("Error fetching user metadata:", error);
            alert("Failed to fetch user metadata. Please try again.");
        } finally {
            console.log("finished getting metadata");
            setIsLoadingMetadata(false);
        }
    }

    async function fetchPersonalizedData() {
        console.log("fetching personalized data");

        const initialInvestment = userMetadata.investmentAmount.toString();
        const finalInvestment = userMetadata.finalInvestmentAmount;
        const time = userMetadata.investmentDuration;
        const risk = userMetadata.riskLevel;

         const url = `https://beta.minvestfinance.com:105/personalized-data?initialInvestment=${initialInvestment}&finalInvestment=${finalInvestment}&time=${time}&risk=${risk}`;
        //const url = `http://localhost:105/personalized-data?initialInvestment=${initialInvestment}&finalInvestment=${finalInvestment}&time=${time}&risk=${risk}`;
        console.log("URL:", url);

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        console.log("stocks API response:", response);

        if (response.ok) {
            const data = await response.json();

            setMinvestData(data);
            console.log("finished getting stocks");
        } else {
            console.error("Error fetching personalized data:", response.statusText);
        }
    }

    const calculateMovingAverage = (arr: number[], windowSize: number): number[] => {
        const result: number[] = [];
        for (let i = 0; i < arr.length; i++) {
            const windowStart = Math.max(0, i - windowSize + 1);
            const windowEnd = i + 1;
            const windowValues = arr.slice(windowStart, windowEnd);
            const sum = windowValues.reduce((a, b) => a + b, 0);
            const average = sum / windowValues.length;
            result.push(average);
        }
        return result;
    };

    // Fetch data using useEffect
    useEffect(() => {
        fetchUserMetadata();

        // wait 2 seconds before fetching stocks
        setTimeout(function () {
            fetchPersonalizedData();
        }, 2000);
    }, []);



    return (
        <>
            {isLoadingMetadata ? (
                <Box textAlign="center" my={4}>
                    <Spinner color="blue.500" />
                    <Text>Loading profile information...</Text>
                </Box>
            ) : (
                <>
                    {(userMetadata.investmentAmount == 0) && (
                        <Modal isOpen={isOpen} onClose={onClose}
                            size={"3xl"}
                        >

                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Welcome to Minvest!</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <iframe
                                        width="100%"
                                        height="400px"
                                        src="https://www.youtube.com/embed/3StgcWg7yVI?si=6rwkcpDIJTNJ2rrz"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>{" "}
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    )}

                    {!isModalOpen && (
                        <Box>
                            <Flex direction={{ base: "column", md: "row" }}>
                                <Box
                                    bg="gray.800"
                                    width={{ base: "100%", md: "450px" }}
                                    mx={3}
                                    borderRadius={10}
                                    mb={{ base: 4, md: 0 }}
                                    p={4}
                                >
                                    <iframe
                                        width="410"
                                        height="200"
                                        src="https://www.youtube.com/embed/3StgcWg7yVI?si=6rwkcpDIJTNJ2rrz"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>{" "}
                                </Box>

                                <Box
                                    bg="gray.800"
                                    width={{ base: "100%", md: "300px" }}
                                    mx={3}
                                    borderRadius={10}
                                    mb={{ base: 4, md: 0 }}
                                    p={4}
                                >
                                    <Text fontSize={18} mb={2}>
                                        Target Value:{" "}
                                        <Text as="span" color="purple.400" fontWeight="bold">
                                            ${userMetadata.finalInvestmentAmount.toLocaleString()}
                                        </Text>
                                    </Text>
                                    <Text fontSize={18} mb={2}>
                                        Time Frame:{" "}
                                        <Text as="span" color="purple.400" fontWeight="bold">
                                            {userMetadata.investmentDuration} years
                                        </Text>
                                    </Text>
                                    <Text fontSize={18} mb={2}>
                                        Risk Tolerance:{" "}
                                        <Text as="span" color="purple.400" fontWeight="bold">
                                            {userMetadata.riskLevel}
                                        </Text>
                                    </Text>
                                </Box>
                                <Box
                                    bg="gray.800"
                                    width={{ base: "100%", md: "450px" }}
                                    mx={3}
                                    mb={{ base: 4, md: 0 }}
                                    borderRadius={10}
                                    p={4}
                                >
                                    <Text as="span" fontWeight="bold">
                                        Quick Links
                                    </Text>
                                    <Text padding={1} fontSize="2x2" color="purple.200">
                                        <a
                                            key={1}
                                            href="https://forms.gle/8sCzHd44hZqquLFp8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Give Feedback
                                        </a>
                                    </Text>
                                    <Text padding={1} fontSize="2x2" color="purple.400">
                                        <a
                                            key={1}
                                            href="/view"
                                            target="_self"
                                            rel="noopener noreferrer"
                                        >
                                            Check Stocks
                                        </a>
                                    </Text>
                                    <Text padding={1} fontSize="2x2" color="purple.400">
                                        <a
                                            key={1}
                                            href="/assistant"
                                            target="_self"
                                            rel="noopener noreferrer"
                                        >
                                            Talk with your Minvest Assistant
                                        </a>
                                    </Text>
                                    <Text padding={1} fontSize="2x2" color="purple.400">
                                        <a
                                            key={1}
                                            href="/profile"
                                            target="_self"
                                            rel="noopener noreferrer"
                                        >
                                            Change Profile Settings
                                        </a>
                                    </Text>

                                    {/* add lines here */}
                                </Box>
                            </Flex>
                            <Box
                                bg="gray.800"
                                width="100%"
                                my={5}
                                mx={0}
                                borderRadius={10}
                                p={4}
                                overflowX="auto"
                            >
                                <Table variant="simple" size="lg">
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Ticker</Th>
                                            <Th>Final Grade</Th>
                                            <Th>Risk Level</Th>
                                            <Th>Industry</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {/* show message if user doesn't have investmentAmount set */}
                                        {userMetadata.investmentAmount == 0 ? (
                                            <Tr>
                                                <Td colSpan={5} fontWeight="bold">
                                                    <Text padding={1} fontSize="2x2" textAlign='center'>
                                                        Before you can view stocks, you need to set your preferences in your{' '}
                                                        <a
                                                            key={1}
                                                            href="/profile"
                                                            target="_self"
                                                            style={{ color: '#9F7AEA' }}
                                                            rel="noopener noreferrer"
                                                        >
                                                            Profile page
                                                        </a>
                                                    </Text>
                                                </Td>
                                            </Tr>
                                        ) : (
                                            /* otherwise, load stocks */
                                            minvestData.slice(0, 10).map((entry: Entry, index) => (
                                                <Tr
                                                    key={index}
                                                    onClick={() => {
                                                        handleClick(entry);
                                                        console.log("wtf");
                                                    }}
                                                >
                                                    <Td
                                                        fontWeight="bold"
                                                        color={getGradeColor(entry["Final Grade"])}
                                                    >
                                                        {entry.Company}
                                                    </Td>
                                                    <Td>{entry.Ticker}</Td>
                                                    <Td>{entry["Final Grade"]}</Td>
                                                    {entry.Beta < 0.25 && (
                                                        <Td fontWeight="bold" color="green.400">
                                                            Very Low Risk
                                                        </Td>
                                                    )}
                                                    {0.25 <= entry.Beta && entry.Beta < 0.75 && (
                                                        <Td fontWeight="bold" color="green.400">
                                                            Low Risk
                                                        </Td>
                                                    )}
                                                    {0.75 <= entry.Beta && entry.Beta < 1.25 && (
                                                        <Td fontWeight="bold" color="yellow.400">
                                                            Medium Risk
                                                        </Td>
                                                    )}
                                                    {1.25 <= entry.Beta && entry.Beta < 2 && (
                                                        <Td fontWeight="bold" color="orange.400">
                                                            High Risk
                                                        </Td>
                                                    )}
                                                    {2 < entry.Beta && (
                                                        <Td fontWeight="bold" color="red.400">
                                                            Very High Risk
                                                        </Td>
                                                    )}
                                                    {/* Replace this with the actual data if available */}
                                                    <Td>{entry.Sector}</Td>{" "}
                                                    {/* Replace this with the actual data if available */}
                                                </Tr>
                                            ))

                                        )}
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    )}

                    {isModalOpen && !isComputerSize && !continueAnyway && (
                        <Box alignItems="center">
                            <Text
                                display={{ base: "flex", md: "none" }}
                                fontSize="2xl"
                                fontWeight="bold"
                                mb={4}
                            >
                                Not optimized for mobile devices. Proceed with desktop version for
                                the best experience!
                            </Text>
                            <Button
                                variant="outline"
                                colorScheme="purple"
                                size="md"
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setContinueAnyway(false);
                                }}
                            >
                                Go Back
                            </Button>
                            <Button
                                variant="outline"
                                colorScheme="purple"
                                size="md"
                                ml={4}
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setContinueAnyway(true);
                                }}
                            >
                                Continue Anyway
                            </Button>
                        </Box>
                    )}

                    {(isComputerSize && isModalOpen || continueAnyway && isModalOpen) &&
                        (
                            <Box>
                                <Flex direction={"row"}>
                                    <Box bg="gray.800" width="70%" borderRadius={10} mr={4} p={4}>
                                        <Text fontWeight="bold" fontSize={24} mb={2}>
                                            Stock Price Chart
                                        </Text>
                                        {!chartMovingAverage && <Line
                                            data={{
                                                labels: stockDates?.slice(-250),
                                                datasets: [
                                                    {
                                                        label: "",
                                                        fill: false,
                                                        backgroundColor: "rgba(255,255,255,1)",
                                                        borderColor: "rgba(159,122,234,1)",
                                                        borderCapStyle: "butt",
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: "miter",
                                                        pointBorderColor: "rgba(229,198,70,1)",
                                                        pointBackgroundColor: "#fff",
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                        pointHoverBorderColor: "rgba(225,225,225,1)",
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 2,
                                                        pointHitRadius: 10,
                                                        data: stockPrices.slice(-250),
                                                    },
                                                ],
                                            }}
                                            options={{
                                                plugins: { legend: { display: false } },
                                                scales: {
                                                    x: { ticks: { display: false } },
                                                    y: { ticks: { color: "white" } },
                                                },
                                            }}
                                        />}

                                        {chartMovingAverage && <><Line
                                            data={{
                                                labels: stockDates?.slice(-250),
                                                datasets: [
                                                    {
                                                        label: "Price",
                                                        fill: false,
                                                        backgroundColor: "rgba(255,255,255,1)",
                                                        borderColor: "rgba(159,122,234,1)",
                                                        borderCapStyle: "butt",
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: "miter",
                                                        pointBorderColor: "rgba(229,198,70,1)",
                                                        pointBackgroundColor: "#fff",
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                        pointHoverBorderColor: "rgba(225,225,225,1)",
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 2,
                                                        pointHitRadius: 10,
                                                        data: stockPrices.slice(-250),
                                                    },

                                                    {
                                                        label: "50 Day Moving Average",
                                                        fill: false,
                                                        backgroundColor: "rgba(255,255,255,1)",
                                                        borderColor: "rgba(102, 255, 51)",
                                                        borderCapStyle: "butt",
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: "miter",
                                                        pointBorderColor: "rgba(102, 255, 51)",
                                                        pointBackgroundColor: "#fff",
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                        pointHoverBorderColor: "rgba(225,225,225,1)",
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 2,
                                                        pointHitRadius: 10,
                                                        data: calculateMovingAverage(stockPrices, 50).slice(-250),
                                                    },

                                                    {
                                                        label: "200 Day Moving Average",
                                                        fill: false,
                                                        backgroundColor: "rgba(255,255,255,1)",
                                                        borderColor: "rgba(255, 153, 0)",
                                                        borderCapStyle: "butt",
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: "miter",
                                                        pointBorderColor: "rgba(255, 153, 0)",
                                                        pointBackgroundColor: "#fff",
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                                        pointHoverBorderColor: "rgba(225,225,225,1)",
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 2,
                                                        pointHitRadius: 10,
                                                        data: calculateMovingAverage(stockPrices, 200).slice(-250),
                                                    },
                                                ],
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: true, // Make sure the legend is visible
                                                        labels: {
                                                            color: 'rgb(255,  255,  255)' // Set the color of the legend text to red
                                                        }
                                                    }
                                                },
                                                scales: {
                                                    x: { ticks: { display: false } },
                                                    y: { ticks: { color: "white" } },
                                                },
                                            }}
                                        />
                                            {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "bullish signal" && <Text fontWeight='bold' textAlign='center' color='green.400'><TriangleUpIcon color='green.400' />Bullish divergence (Buy signal)</Text>}
                                            {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "bearish signal" && <Text fontWeight='bold' textAlign='center' color='red.400'><TriangleDownIcon color='red.400' />Bearish divergence (Sell signal)</Text>}
                                            {analyzeSignal(stockPrices.slice(-250).reverse(), calculateMovingAverage(stockPrices, 50).slice(-250).reverse(), calculateMovingAverage(stockPrices, 200).slice(-250).reverse()) == "neutral" && <Text fontWeight='bold' textAlign='center'>Neutral signal</Text>}</>}
                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                setChartMovingAverage(false)
                                            }}
                                            sx={{ marginTop: 2 }}
                                        >
                                            <Text>Default</Text>
                                        </Button>
                                        <Button
                                            sx={{ marginLeft: 2, marginTop: 2 }}
                                            size="sm"
                                            onClick={() => {
                                                setChartMovingAverage(true)
                                            }}
                                        >
                                            <Text>Moving Average</Text>
                                        </Button>
                                    </Box>
                                    <Box bg="gray.800" width="30%" borderRadius={10} p={4}>
                                        <Flex direction={"row"}>
                                            <Text fontWeight="bold" fontSize={24} mb={2}>
                                                Overview
                                            </Text>
                                            <IconButton
                                                icon={<CloseButton />}
                                                aria-label="Close Stock"
                                                variant="ghost"
                                                size="sm"
                                                color="white"
                                                position={"absolute"}
                                                right={4}
                                                onClick={() => {
                                                    setIsModalOpen(false);
                                                    setContinueAnyway(false);
                                                }}
                                            />
                                        </Flex>

                                        <Flex direction={"row"}>
                                            <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                                Price:
                                            </Text>
                                            <Text fontSize={14} mb={2}>
                                                {selectedEntry?.Price}
                                            </Text>
                                        </Flex>
                                        <Flex direction={"row"}>
                                            <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                                Risk Level:
                                            </Text>
                                            <Text fontSize={14} mb={2}>
                                                {selectedEntry?.Beta}
                                            </Text>
                                        </Flex>
                                        <Flex direction={"row"}>
                                            <Text fontWeight="bold" fontSize={14} mr={2} mb={2}>
                                                Industry Name:
                                            </Text>
                                            <Text fontSize={14} mb={2}>
                                                {selectedEntry?.Sector}
                                            </Text>
                                        </Flex>
                                        {selectedEntry &&
                                            !selectedStocks.some(
                                                (stock) => stock.Ticker === selectedEntry.Ticker
                                            ) && (
                                                <Button
                                                    onClick={() => {
                                                        if (selectedEntry) {
                                                            setSelectedStocks((prevStocks) => [
                                                                ...prevStocks,
                                                                selectedEntry,
                                                            ]);
                                                            setUpdate(true);
                                                        }
                                                    }}
                                                >
                                                    <Text>Add To Portfolio</Text>
                                                </Button>
                                            )}

                                        {selectedEntry &&
                                            selectedStocks.some(
                                                (stock) => stock.Ticker === selectedEntry.Ticker
                                            ) && (
                                                <ButtonGroup size="sm" isAttached>
                                                    <IconButton
                                                        aria-label="Minus"
                                                        icon={<MinusIcon />}
                                                        onClick={() => {
                                                            if (selectedEntry) {
                                                                const indexToRemove =
                                                                    selectedStocks.findIndex(
                                                                        (stock) =>
                                                                            stock.Company ===
                                                                            selectedEntry.Company
                                                                    );

                                                                if (indexToRemove !== -1) {
                                                                    const updatedStocks = [
                                                                        ...selectedStocks,
                                                                    ];
                                                                    updatedStocks.splice(
                                                                        indexToRemove,
                                                                        1
                                                                    );
                                                                    setSelectedStocks(
                                                                        updatedStocks
                                                                    );
                                                                    setUpdate(true);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Button>
                                                        Added{" "}
                                                        {
                                                            selectedStocks.filter(
                                                                (stock) =>
                                                                    stock.Ticker ===
                                                                    selectedEntry.Ticker
                                                            ).length
                                                        }
                                                    </Button>
                                                    <IconButton
                                                        aria-label="Add"
                                                        icon={<AddIcon />}
                                                        onClick={() => {
                                                            if (selectedEntry) {
                                                                setSelectedStocks(
                                                                    (prevStocks) => [
                                                                        ...prevStocks,
                                                                        selectedEntry,
                                                                    ]
                                                                );
                                                                setUpdate(true);
                                                            }
                                                        }}
                                                    />
                                                </ButtonGroup>
                                            )}
                                    </Box>
                                </Flex>
                                <Flex direction={"row"}>
                                    <Box
                                        bg="gray.800"
                                        width="50%"
                                        borderRadius={10}
                                        mt={4}
                                        mr={4}
                                        p={4}
                                    >
                                        <Flex direction={"row"}>
                                            <Text fontWeight="bold" fontSize={30}>
                                                {selectedEntry?.Company}
                                            </Text>
                                            <Text
                                                color="purple.400"
                                                ml={2}
                                                mt={3}
                                                fontWeight="bold"
                                            >
                                                Final Grade:
                                            </Text>
                                            {selectedEntry?.["Final Grade"] && (
                                                <Text
                                                    mt={3}
                                                    color={getGradeColor(
                                                        selectedEntry?.["Final Grade"]
                                                    )}
                                                    marginLeft={1}
                                                    marginRight={1}
                                                    fontWeight="bold"
                                                >
                                                    {selectedEntry?.["Final Grade"]}
                                                </Text>
                                            )}
                                        </Flex>
                                        <Text fontSize={18} mb={2}>
                                            {selectedEntry?.Ticker}
                                        </Text>
                                        <Text fontSize={14}>{selectedEntry?.Description}</Text>
                                    </Box>
                                    <Box bg="gray.800" width="50%" borderRadius={10} mt={4} p={4}>
                                        <Accordion allowToggle>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box as="span" flex="1" textAlign="left">
                                                            <Flex direction={"row"}>
                                                                <Text
                                                                    as="span"
                                                                    fontWeight={"bold"}
                                                                    fontSize={24}
                                                                >
                                                                    Valuation Grade
                                                                </Text>
                                                                {selectedEntry?.[
                                                                    "Valuation Grade"
                                                                ] && (
                                                                        <Text
                                                                            as="span"
                                                                            fontSize={24}
                                                                            color={getGradeColor(
                                                                                selectedEntry?.[
                                                                                "Valuation Grade"
                                                                                ]
                                                                            )}
                                                                            marginLeft={4}
                                                                            marginRight={1}
                                                                            fontWeight="bold"
                                                                        >
                                                                            {
                                                                                selectedEntry?.[
                                                                                "Valuation Grade"
                                                                                ]
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                <CustomTooltip label="A measure of how overpriced or underpriced a stock is. With these metrics, a lower number compared to industry peers is better."></CustomTooltip>
                                                            </Flex>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much people are willing to pay for a company's shares compared to the money the company makes."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            P/E Ratio (Price-to-Earnings Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.PE?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["PE Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["PE Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["PE Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much people are willing to pay for a company's shares compared to the money the company makes from selling stuff."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            P/S Ratio (Price-to-Sales Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.PS?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["PS Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["PS Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["PS Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much people are willing to pay for a company's shares compared to the value of all its stuff (like buildings and equipment)."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            P/B Ratio (Price-to-Book Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.PB?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["PB Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["PB Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["PB Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="Considers how fast a company is growing when looking at its share price compared to its earnings."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            PEG Ratio (Price/Earnings to Growth
                                                            Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.PEG?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["PEG Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["PEG Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["PEG Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much people think a company is worth compared to the money it makes from selling things."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            EV/R Ratio (Enterprise
                                                            Value-to-Revenue Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.["EV/S"]?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["EV/S Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["EV/S Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["EV/S Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much people think a company is worth compared to the money it makes, not counting some tricky things."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            EV/EBITDA Ratio (Enterprise
                                                            Value-to-EBITDA Ratio)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.[
                                                                "EV/EBITDA"
                                                            ]?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["EV/EBITDA Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.[
                                                                    "EV/EBITDA Grade"
                                                                    ]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {
                                                                    selectedEntry?.[
                                                                    "EV/EBITDA Grade"
                                                                    ]
                                                                }
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                </AccordionPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box as="span" flex="1" textAlign="left">
                                                            <Flex direction={"row"}>
                                                                <Text
                                                                    fontWeight={"bold"}
                                                                    fontSize={24}
                                                                >
                                                                    Financial Grade
                                                                </Text>
                                                                {selectedEntry?.[
                                                                    "Financial Grade"
                                                                ] && (
                                                                        <Text
                                                                            as="span"
                                                                            fontSize={24}
                                                                            color={getGradeColor(
                                                                                selectedEntry?.[
                                                                                "Financial Grade"
                                                                                ]
                                                                            )}
                                                                            marginLeft={4}
                                                                            marginRight={1}
                                                                            fontWeight="bold"
                                                                        >
                                                                            {
                                                                                selectedEntry?.[
                                                                                "Financial Grade"
                                                                                ]
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                <CustomTooltip label="These show if the company is in a good financial position to keep growing and generating shareholder value."></CustomTooltip>
                                                            </Flex>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Flex direction={"row"}>
                                                        <Text
                                                            as={"span"}
                                                            fontWeight={"bold"}
                                                            fontSize={18}
                                                            mt={0.5}
                                                            color={"purple.400"}
                                                        >
                                                            Management Effectiveness
                                                        </Text>
                                                        <CustomTooltip label="This shows how good of a job the company is doing with generating returns from assets and money that shareholders invest in it. Higher values are always better."></CustomTooltip>
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How good a company is at making money with all the things it owns."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Return on Assets (ROA)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.ROA?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["ROA Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["ROA Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["ROA Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How good a company is at making money compared to the money its owners put in."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Return on Equity (ROE)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.ROE?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["ROE Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["ROE Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["ROE Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex direction={"row"} mt={2}>
                                                        <Text
                                                            as={"span"}
                                                            fontWeight={"bold"}
                                                            fontSize={18}
                                                            mt={0.5}
                                                            color={"purple.400"}
                                                        >
                                                            Profitability
                                                        </Text>
                                                        <CustomTooltip label="This shows how good the company is at making money and minimizing costs from its operations. Higher values are always better."></CustomTooltip>
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much money a company has left after subtracting what it cost to make and sell its products."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Gross Margin
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.GM?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["GM Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["GM Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["GM Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much money a company makes before subtracting some tricky things, compared to its revenue."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            EBITDA Margin (Earnings Before
                                                            Interest, Taxes, Depreciation, and
                                                            Amortization Margin)
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.EM?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["EM Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["EM Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["EM Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much money a company makes after subtracting all the costs of running the business, compared to its revenue."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Operating Margin
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.OM?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["OM Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["OM Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["OM Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much money a company makes after subtracting all the costs, including the tricky things, compared to its revenue."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Profit Margin
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.PM?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["PM Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["PM Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["PM Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex direction={"row"} mt={2}>
                                                        <Text
                                                            as={"span"}
                                                            fontWeight={"bold"}
                                                            fontSize={18}
                                                            mt={0.5}
                                                            color={"purple.400"}
                                                        >
                                                            Liquidity/Leverage
                                                        </Text>
                                                        <CustomTooltip label="This shows if the company is taking on too much debt and might be unable to meet short term obligations. Lower values are usually better."></CustomTooltip>
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="Whether a company has enough stuff (assets) to cover what it owes (liabilities) right now."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Current Ratio
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.CR?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["CR Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["CR Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["CR Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much a company owes compared to how much its owners put in."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Debt to Equity Ratio
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.DE?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["DE Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["DE Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["DE Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    <Flex direction={"row"} mt={2}>
                                                        <Text
                                                            as={"span"}
                                                            fontWeight={"bold"}
                                                            fontSize={18}
                                                            mt={0.5}
                                                            color={"purple.400"}
                                                        >
                                                            Growth
                                                        </Text>
                                                        <CustomTooltip label="This is self-explanatory; is the company growing its revenue and profit? Higher numbers are always better."></CustomTooltip>
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much more money a company is making compared to before."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Earnings Growth
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.EG?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["EG Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["EG Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["EG Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={"space-between"}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <CustomTooltip label="How much more money a company is making from selling things compared to before."></CustomTooltip>
                                                        <Text
                                                            as="span"
                                                            fontWeight={"bold"}
                                                            fontSize={14}
                                                            flex="1"
                                                        >
                                                            Revenue Growth
                                                        </Text>

                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                            mr={6}
                                                            fontSize={14}
                                                        >
                                                            {selectedEntry?.RG?.toFixed(2)}
                                                        </Text>

                                                        {selectedEntry?.["RG Grade"] && (
                                                            <Text
                                                                as="span"
                                                                fontSize={14}
                                                                color={getGradeColor(
                                                                    selectedEntry?.["RG Grade"]
                                                                )}
                                                                marginLeft={4}
                                                                fontWeight="bold"
                                                            >
                                                                {selectedEntry?.["RG Grade"]}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </Box>
                                </Flex>
                            </Box>
                        )}
                </>
            )}

        </>
    );
}
export default Dashboard;
