import React from "react";
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

import { useAuth0 } from "@auth0/auth0-react";

export default function Hero() {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    const handleLogin = () => {
        console.log(isAuthenticated);
        loginWithRedirect();
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <Flex minH="100vh" align="center" justify="center" bg="gray.900">
            <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
                <Stack align="center">
                    <Heading fontSize="4xl">Sign in or sign up</Heading>
                    <Text fontSize="lg" color="gray.600">
                        to enjoy all of our cool <Link color="purple.400">features</Link> ✌️
                    </Text>
                </Stack>
                <Box rounded="lg" bg={useColorModeValue("white", "gray.700")} boxShadow="lg" p={8}>
                    <Stack spacing={4}>
                        {isAuthenticated ? (
                            <Button
                                bg="purple.400"
                                color="white"
                                onClick={handleLogout}
                                _hover={{
                                    bg: "purple.500",
                                }}
                            >
                                Log out
                            </Button>
                        ) : (
                            <>
                                <Button
                                    bg="purple.400"
                                    color="white"
                                    onClick={handleLogin}
                                    _hover={{
                                        bg: "purple.500",
                                    }}
                                >
                                    Sign in
                                </Button>
                                <Button
                                    bg="purple.400"
                                    color="white"
                                    onClick={handleLogin}
                                    _hover={{
                                        bg: "purple.500",
                                    }}
                                >
                                    Sign up
                                </Button>
                            </>
                        )}
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}
