import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Box,
    Text,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Radio,
    RadioGroup,
    HStack,
    Button,
    Spinner,
} from "@chakra-ui/react";
import { type UserMetadata } from "../models";
function Profile() {
    const { user, loginWithRedirect } = useAuth0();
    if (user == null) {
        loginWithRedirect();
        return;
    }
    const [investmentAmount, setInvestmentAmount] = React.useState("");
    const [finalInvestmentAmount, setFinalInvestmentAmount] = React.useState("");
    const [investmentDuration, setInvestmentDuration] = React.useState("");
    const [riskLevel, setRiskLevel] = React.useState("");
    const [isLoadingMetadata, setIsLoadingMetadata] = React.useState(true);
    const [userMetadata, setUserMetadata] = React.useState<UserMetadata>({
        investmentAmount: 1000,
        finalInvestmentAmount: 2000,
        investmentDuration: 12,
        riskLevel: "low",
        selectedStocks: []
    });
    const fetchUserMetadata = () => {
        fetch("https://beta.minvestfinance.com:3001/get-user-metadata", {
        // fetch("http://localhost:3001/get-user-metadata", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: user.sub,
            }),
        })
            .then(async (response) => {
                if (response.ok) {
                    const data = await response.json();
                    setUserMetadata(data.userMetadata);
                } else {
                    throw new Error("Failed to fetch user metadata");
                }
            })
            .catch((error) => {
                console.error("Error fetching user metadata:", error);
                alert("Failed to fetch user metadata. Please try again.");
            })
            .finally(() => {
                setIsLoadingMetadata(false);
            });
    };
    React.useEffect(() => {
        fetchUserMetadata();
    }, []);
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const userMetadata = {
            investmentAmount: parseInt(investmentAmount),
            finalInvestmentAmount: parseInt(finalInvestmentAmount),
            investmentDuration: parseFloat(investmentDuration),
            riskLevel,
        };
        setInvestmentAmount("");
        setFinalInvestmentAmount("");
        setInvestmentDuration("");
        setRiskLevel("");
        
        try {
            const response = await fetch("https://beta.minvestfinance.com:3001/update-user-metadata", {
            // const response = await fetch("http://localhost:3001/update-user-metadata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: user.sub,
                    userMetadata,
                }),
            });
            if (response.ok) {
                alert("Profile updated successfully!");
                fetchUserMetadata();
            } else {
                throw new Error("Failed to update profile");
            }
        } catch (error) {
            console.error("Error updating user metadata:", error);
            alert(`Failed to update profile. Please try again.${user.sub}`);
        }
    };
    return (
        <>
            <Box>
                <Text fontSize="2xl" mb={4}>
                    Profile
                </Text>
                <Text>Email: {user.email}</Text>
                <Text>Name: {user.name}</Text>
            </Box>
            
            <Box>
                {isLoadingMetadata ? (
                    <Box textAlign="center" my={4}>
                        <Spinner color="blue.500" />
                        <Text>Loading profile information...</Text>
                    </Box>
                ) : (
                    <Box mt={8}>
                        <Text fontSize="2xl" mb={4}>
                            Current Preferences
                        </Text>
                        <Text>Initial Investment Amount: {userMetadata.investmentAmount || "Not set"}</Text>
                        <Text>
                            Target Investment Amount:{" "}
                            {userMetadata.finalInvestmentAmount || "Not set"}
                        </Text>
                        <Text>
                            Investment Duration: {userMetadata.investmentDuration || "Not set"}{" "}
                            years
                        </Text>
                        <Text>Risk Level: {userMetadata.riskLevel || "Not set"}</Text>
                    </Box>
                )}
                <br />
                <Text fontSize="2xl" mb={4}>
                    Edit Profile
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl id="investment-amount">
                            <FormLabel>Inital Investment Amount</FormLabel>
                            <Input
                                type="number"
                                value={investmentAmount}
                                onChange={(e) => {
                                    setInvestmentAmount(e.target.value);
                                }}
                                required
                            />
                        </FormControl>
                        <FormControl id="final-investment-amount">
                            <FormLabel>Target Investment Amount</FormLabel>
                            <Input
                                type="number"
                                value={finalInvestmentAmount}
                                onChange={(e) => {
                                    setFinalInvestmentAmount(e.target.value);
                                }}
                                required
                            />
                        </FormControl>
                        <FormControl id="investment-duration">
                            <FormLabel>Investment Duration (years)</FormLabel>
                            <Input
                                type="number"
                                value={investmentDuration}
                                onChange={(e) => {
                                    setInvestmentDuration(e.target.value);
                                }}
                                required
                            />
                        </FormControl>
                        <FormControl as="fieldset">
                            <FormLabel as="legend">Risk Level</FormLabel>
                            <RadioGroup value={riskLevel} onChange={setRiskLevel}>
                                <HStack spacing="24px">
                                    <Radio value="Low">Low</Radio>
                                    <Radio value="Medium">Medium</Radio>
                                    <Radio value="High">High</Radio>
                                </HStack>
                            </RadioGroup>
                        </FormControl>
                        <Button type="submit" colorScheme="purple">
                            Save Preferences
                        </Button>
                    </VStack>
                </form>
            </Box>
        </>
    );
}
export default Profile;
