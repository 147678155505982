// Import required modules and components
import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Input, Avatar, Button, Divider } from "@chakra-ui/react";
import axios from 'axios';
import keywordExtractor from 'keyword-extractor';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-scroll';
import { Configuration, OpenAIApi } from 'openai';

import { parseString } from "xml2js";


// Define the structure of a message
interface Message {
  role: string;
  content: string;
}

// Define the structure of an article
interface Article {
  title: string;
  link: string;
  description: string;
  image: string; // Added image property
}

// Main component
function Assistant() {
  const [input, setInput] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([
    { role: "assistant", content: "Hi, I'm your Minvest Assistant! Feel free to ask for information on topics related to finance and investing. However, I am not knowledgeable on recent events, and I should not be used to make serious financial decisions. What's on your mind?" },
  ]);
  const [loadState, setLoadState] = useState<boolean>(false);
  const [articles, setArticles] = useState<Article[]>([]);

  const { user, loginWithRedirect } = useAuth0();
    // If user is not authenticated, redirect to login
    if (!user) {
        loginWithRedirect();
        return null; // Return something (null in this case) if user is not defined
    }

  // Set up OpenAI API configuration
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
  });

  // Initialize OpenAI API instance
  const openai = new OpenAIApi(configuration);

    // Function to add a message to the chat
  const addMessage = (text: string, sender: string) => {
    setMessages((prevMessages) => [...prevMessages, { role: sender, content: text }]);
  };

// Function to update the most recent assistant's message
  const updateMessage = (newValue: string) => {
    setMessages((prevArray) => {
      const updatedArray = [...prevArray];
      updatedArray[prevArray.length - 1] = { role: "assistant", content: newValue };
      return updatedArray;
    });
  };

    // Function to extract the most prominent keyword from a text
  const extractMostProminentKeyword = (text: string) => {
    const keywords = keywordExtractor.extract(text, {
      language: 'english',
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: true,
    });

    return keywords[0] || '';
  };

    // Function to send a user message and receive a response
  const sendMessage = async () => {
    if (!input) return;

    setLoadState(true);
    addMessage(input, "user");
    addMessage("Thinking of a response... Please wait...", 'bot');
    setArticles([])

          // Send a message to the OpenAI API for processing
    try {
      const response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'system', content: 'You are the Minvest Assistant, an AI who does their best to answer financial questions. However, you are NOT and have NEVER BEEN a financial expert - stock recommendations are out of the question. You also use keywords that are frequently used in New York Times articles. If you are asked about Minvest, you respond saying that it is a service that empowers Gen Z to take control of their financial future by providing information and news related to finance (They are NOT financial experts or a brokerage, and do not offer stock recommendations, suggestions, or advice). If they ask how it was made, you state that it was created through the Academies of Loudoun Entrepreneurshup program by a team of high school students. If they would like to get involved with Minvest, you tell them to join the internship program/newsletter and spread the word - you also provide the website (minvestfinance.com). About sensitive financial advice: You ONLY provide information on stocks and ETFs - you DO NOT provide advice on bonds or any other form of assets. You are also NOT POLITICAL whatsoever. You avoid talking about things that are not related to finance. Your responses appeal to teenage audiences and are lighthearted (albeit professional), and they are concise.' }, { role: 'user', content: input }],
        temperature: 0.7
      });

      // Extract the response message from the API response
        // note that the ! after 'message' and 'content' bypass TSX's error code
        // 
      const messageFromResponse = response.data.choices[0].message!.content!.toString();
      await updateMessage(messageFromResponse);
      setLoadState(false);

      // Use your extractMostProminentKeyword function here
      const keyword = extractMostProminentKeyword(messageFromResponse);
      const messageKeyword = extractMostProminentKeyword(input)

      // Fetch the RSS feed as you did before
      const articleFeed = await axios.get(`https://search.cnbc.com/rs/search/combinedcms/view.xml?partnerId=wrss01&id=100003114`);
      parseString(articleFeed.data, (err, result) => {
        // Parse and manipulate the articles data
        if (err) {
          console.error('Error parsing XML:', err);
          return;
        }
        const parsedArticles = result?.rss?.channel[0]?.item || [];

        for (const element of parsedArticles) {
          if(element.description != null){
            element.title[0] = element.title[0] + element.description[0]
        }
      }

          // Filter and map the articles
          const filteredArticles = parsedArticles.filter((item: any) =>
            item.title[0]?.toLowerCase().includes(keyword.toLowerCase() || item.title[0]?.toLowerCase().includes(messageKeyword.toLowerCase())
          )).map((item: any) => ({
            title: item.title[0],
            link: item.link[0],
            image: item.enclosure?.[0].$.url || '', // Get the image URL if available
          }));

        setArticles(filteredArticles);
        console.log(filteredArticles)
      });
    } catch (error) {
      console.error('Error sending message:', error);
      addMessage("Sorry, it seems I've come across an error. Please try again.", 'bot');
      setArticles([]);
      setLoadState(false);
    }
  };

  return (
    <>
   

{/* Display message history and related articles */}
        <ul>
          {messages.map(({ role, content }, index) => (
            <Box padding={1} marginTop={5} key={index} bg="gray.800" borderRadius={10} position="relative">
              {index % 2 !== 0 ? (
                <Avatar margin={2} size={"sm"} src={user.picture} />
              ) : (
                <Avatar margin={2} size={"sm"} src={"https://static.wixstatic.com/media/2317a4_31e8c0f34e6041ef89660e29921c06cd~mv2.png/v1/fill/w_118,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LogoWhiteRing%20(1)_PNG.png"} />
              )}
              <Text padding={2} fontSize="1x2" mb={4}>
                {content}
              </Text>
              {index == messages.length - 1 && index != 0 && index % 2 == 0 && articles.length > 0 &&
              
              <ul><Text padding={2} fontSize="2x1" fontWeight="bold">Related Articles</Text>
              {articles.map((article) => (
                <Text key={1} padding={2} fontSize="2x2" color="purple.400"><a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a></Text>
            ))}</ul>}
              
            </Box>
          ))}
        </ul>

        <Divider marginTop={5} marginBottom={20}/>

       {/* Input and send button */}
       <Flex position='fixed' bottom={2} width='50%' left='32%' marginTop={10}>
        <Input
        backgroundColor="gray.800"
          onChange={(e) => setInput(e.target.value)}
          required
          placeholder="Talk with your Minvest Assistant..."
          marginRight={1}
          width='200%'
        />

        {!loadState ? <Button onClick={sendMessage}>Send</Button> : <Button disabled>Send</Button>}
      </Flex>
    </>
  );
}

export default Assistant;
