import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import App from "./App";

const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";

const container = document.getElementById("root");

if (container == null) {
    throw new Error("Could not find app container");
}

const root = createRoot(container);

root.render(
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{ redirect_uri: window.location.origin }}
    >
        <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode="dark" />
            <App />
        </ChakraProvider>
    </Auth0Provider>
);
